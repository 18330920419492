import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#BCD0E4',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const VerifyLogs = ({ customerLogs }) => {

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 210, overflowX: 'auto' }}
    >
      <Table sx={{ minWidth: 500 }} aria-label="customized table">
        <TableHead sx={{ position: 'sticky', top: 0 }}>
          <TableRow>
            <StyledTableCell style={{textAlign: 'center', color: '#000', fontWeight: 'bold'}}>Date</StyledTableCell>
            <StyledTableCell style={{textAlign: 'center', color: '#000', fontWeight: 'bold'}}>Time</StyledTableCell>
            <StyledTableCell style={{textAlign: 'center', color: '#000', fontWeight: 'bold'}}>Type</StyledTableCell>
            <StyledTableCell style={{textAlign: 'center', color: '#000', fontWeight: 'bold'}}>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customerLogs.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell>
                {moment(row.created_at).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell>{moment(row.created_at).format('h:mm a')}</TableCell>
              <TableCell>
                {row.type}
              </TableCell>
              <TableCell>
                {row.status === 1 ? 'Verified' : 'Not Verified'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VerifyLogs;