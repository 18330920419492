import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
} from "@mui/material";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#BCD0E4",
    minWidth: 150,
    textAlign: "center",
    color: "#000",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const CustomerOrdersTable = ({ orders }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: 210, overflowX: "auto" }}
    >
      <Table sx={{ minWidth: 500 }} aria-label="customized table">
        <TableHead sx={{ position: "sticky", top: 0 }}>
          <TableRow>
            <StyledTableCell>Product Name</StyledTableCell>
            <StyledTableCell>Shipment Status</StyledTableCell>
            <StyledTableCell>Shop Name</StyledTableCell>
            <StyledTableCell>Quantity</StyledTableCell>
            <StyledTableCell>Created Date</StyledTableCell>
            <StyledTableCell>Weight</StyledTableCell>
            <StyledTableCell>Tracking Company</StyledTableCell>
            <StyledTableCell>Total Discount</StyledTableCell>
            <StyledTableCell>Total Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((row, index) => {
            const orderApiId = row.admin_graphql_api_id;
            const splitId = orderApiId.split("/");
            const orderId = splitId[splitId.length - 1];

            return (
              <TableRow
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `https://${row?.store_name}.myshopify.com/admin/orders/${orderId}`, '_blank'
                  );
                }}
              >
                <TableCell style={{ textAlign: "center" }}>
                  <Tooltip title={row?.line_items[0]?.name}>
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 150,
                      }}
                    >
                      {row?.line_items[0]?.name}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row?.fulfillments[0]?.shipment_status
                    ? row?.fulfillments[0]?.shipment_status
                        .charAt(0)
                        .toUpperCase() +
                      row?.fulfillments[0]?.shipment_status.slice(1)
                    : "N/A"}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <Tooltip title={row?.store_name}>
                    <Typography
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: 150,
                      }}
                    >
                      {row?.store_name}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row?.line_items[0]?.quantity}
                </TableCell>
                <TableCell style={{ textAlign: "center", wordWrap: "" }}>
                  {moment(row?.created_at).format("h:mm a")}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row?.total_weight}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row?.fulfillments[0]?.tracking_company}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row?.current_total_discounts}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  {row?.current_total_price}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomerOrdersTable;
